import * as React from "react"

import t from "@translations"
import Seo from "@components/seo"
import Layout from "@components/layout"
import { CommonPageWrap } from "@components/shared/styled"

type Props = {
    location: Location
}

const NotFoundPage = ({ location }: Props) => {
    return (
        <Layout location={location}>
            <Seo title={t[404]} />
            <CommonPageWrap>
                <h1>{t[404]}</h1>
                <p>{t[`404-text`]}</p>
            </CommonPageWrap>
        </Layout>
    )
}

export default NotFoundPage
